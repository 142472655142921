import styled from "styled-components";
import { Button } from "../../styles/globalStyles";

export const ServiceContainer = styled.div`
    display: flex;
    border: 1px solid #ddd;
    width: 350px;
    height: 200px;
    margin: 10px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    border-top: 5px solid ${props => props.activityStatus}
    
`;