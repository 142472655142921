import "./Modal.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show
    ? "modal1 display-block"
    : "modal1 display-none";

  return (
    <div onClick={handleClose} className={showHideClassName}>
      <section onClick={e => {
          e.stopPropagation();
        }} className="modal-main">
          <div className="modal-header">
          <button
          type="button"
          className="close-dialog-btn"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={solid("times")} size='lg' />
        </button>
        </div>
        <div className="modal-body-scroll">
        {children}
        </div>
      </section>
    </div>
  );
};

export default Modal;
