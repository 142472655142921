import React, { useState, useEffect } from "react";
import Service from "../../components/service/ServiceInstance.component.jsx";
import services from "../../api-services/orchestrator-service/orchestrator-services.data.js";
import OrchestratorClient from "../../api-services/orchestrator-service/orcherstrator-service-client";
import "./AllServices.css";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"

const AllServices = () => {
  const [activeServices, setActiveServices] = useState([]);
  const [loading, setLoading] = useState(false);

  let client = new OrchestratorClient();

  const PingAll = async () => {
    setLoading(true);
    await client.PingAll().then((res) => {
      setLoading(false);
      setActiveServices(res.data);
    });
  };

  const StartConfigurationRunnerAgain = async () => {
    await client
      .StartConfigurationRunnerAgain()
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        toast.success("Vec ste pokrenuli sistem!", {hideProgressBar: true});
      });
  };

  const StopConfigurationRunnerAndAllServices = async () => {
    await client
      .StopConfigurationRunnerAndAllServices()
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        toast.error("Vec ste zaustavili sistem!", {hideProgressBar: true});
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      PingAll();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const isServiceActive = (name) => {
    if (activeServices !== undefined) {
      let service = activeServices.filter(
        (x) => x.ServiceInfo.ServiceIdentifier === name
      )[0];
      if (service !== undefined)
        if (service.Stable === true) return true;
        else if (service.Stable === false) return false;
        else return undefined;
    } else return null;
  };

  return (
    <>
      <div>
        <button className="btn btn-default btn-lg m-2" onClick={PingAll}>
        <FontAwesomeIcon icon={solid("refresh")} size='lg' />
          Osvezi
        </button>
        <button
          className="btn btn-success btn-lg m-2"
          onClick={StartConfigurationRunnerAgain}
        >
        <FontAwesomeIcon icon={solid("play")} size='lg' />
          Pokreni sve
        </button>
        <button
          className="btn btn-danger btn-lg m-2"
          onClick={StopConfigurationRunnerAndAllServices}
        >
        <FontAwesomeIcon icon={solid("stop")} size='lg' />
          Zaustavi sve
        </button>
      </div>
      <div className="all_services_container">
        {services.map((s, index) => (
          <div key={index}>
            <Service
              key={s.ServiceIdentifier}
              ServiceIdentifier={s.ServiceIdentifier}
              Active={isServiceActive(s.ServiceIdentifier)}
              service={
                activeServices?.filter(
                  (x) => x.ServiceInfo.ServiceIdentifier === s.ServiceIdentifier
                )[0]
              }
            />
          </div>
        ))}
        <ToastContainer limit={1}/>
      </div>
    </>
  );
};

export default AllServices;
