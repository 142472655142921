import React, { useState, useEffect } from "react";
import "./ServiceConfiguration.style.css";

const ServiceConfiguration = (props) => {
  const [ServiceConfig, setServiceConfig] = useState([]);

  useEffect(() => {
    setServiceConfig(props.serviceConfig);
  });

  return (
    <>
      <div className="service-config container">
        <div className="col-12">{ServiceConfig}</div>
      </div>
    </>
  );
};

export default ServiceConfiguration;
