import React, { useState, useEffect } from "react";
import "./Service.style.css";
import Topics from "../topics/Topics.component";
import ServiceInfo from "../service-info/ServiceInfo.component";
import Modal from "../../commons/modal/Modal.component";
import ServiceConfiguration from '../service-configuration/ServiceConfiguration.component'
import OrchestratorClient from "../../api-services/orchestrator-service/orcherstrator-service-client";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { RefreshButton, ServiceContainer } from "./ServiceStyles";
import { Button } from "../../styles/globalStyles";
import { DeleteButton } from "./ServiceStyles";

const getActivity = (active) => {

  const val =
    active === true
      ? "#28a745"
      : active === false
        ? "orange"
        : "#d01919";
  return val;
};



const Service = (props) => {

  let client = new OrchestratorClient();

  const ReloadSpecificService = async (serviceId) => {

    if (serviceId == undefined) {
      toast.info("Servis nije pokrenut kako bi ga resetovali.", { hideProgressBar: true });
      return;
    }
    await client
      .StopSpecificService(serviceId)
      .then((res) => {
        toast.info("Uspesno ste restartovali servis sa id-jem:" + serviceId, { hideProgressBar: true });
        console.log(res);
      })
      .catch((error) => {
        toast.error("Nesupesno ste restartovali servisa sa id-jem:" + serviceId, { hideProgressBar: true });
      });
  };

  const activity = getActivity(props.Active);
  const [ServiceTaskInformations, setServiceTaskInformations] = useState([]);
  const [isHovering, setIsHovering] = useState(false);
  const [ServiceStatus, setServiceStatus] = useState("");
  const [pollCount, setPollCount] = useState("");
  const [sameResponseCount, setSameResponseCount] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [serviceRecentlyConfigured, setServiceRecentlyConfigured] = useState(null);
  const [show, setShow] = useState(false);

  const handleMouseOver = () => setIsHovering(true);

  const handleMouseOut = () => setIsHovering(false);

  const showModal = () => setShow(true);

  const hideModal = () => setShow(false);

  useEffect(() => {
    setServiceTaskInformations(
      props.service?.ServiceInfo.ServiceTaskInformations
    );
    setServiceStatus(props.service?.ServiceInfo.ServiceStatus);
    setPollCount(props.service?.PollCount);
    setSameResponseCount(props.service?.SameResponseCount);
    setServiceId(props.service?.ServiceInfo.ServiceId);
    setServiceRecentlyConfigured(props.service?.ServiceRecentlyConfigured);
  }, [props.service]);

  return (
    <>
      <div>
        <ServiceContainer className="test" activityStatus={activity}>
          <div className="container">
            <div className="row service_name">
              <div className="col-1">
                <FontAwesomeIcon icon={solid("rotate-right")} size='lg' pull="left" onClick={() => { ReloadSpecificService(props.service?.ServiceInfo.ServiceId) }} />
              </div>
              <div className="col-10" onClick={showModal}>
                <h4 className="service_type_name">{props.ServiceIdentifier}</h4>
              </div>
              <div className="col-1">
                <FontAwesomeIcon icon={solid("info")} size='lg' pull="right" onClick={showModal} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
              </div>
            </div>
            <ServiceInfo
              ServiceStatus={ServiceStatus}
              pollCount={pollCount}
              sameResponseCount={sameResponseCount}
              serviceId={serviceId}
              serviceRecentlyConfigured={serviceRecentlyConfigured}
            />
            {isHovering && (
              <div className="topics">
                {ServiceTaskInformations?.map((item, index) => (
                  <Topics key={index} ServiceTaskInfo={item} />
                ))}
              </div>
            )}
          </div>
        </ServiceContainer>
      </div>
      <Modal show={show} handleClose={hideModal}>
        {ServiceTaskInformations?.map((item, index) => (
          <div key={index} style={{ marginBottom: "15px" }}>
            <ServiceConfiguration key={index + "-config"} serviceConfig={item.Configuration} />
            <Topics key={index + "-topic"} ServiceTaskInfo={item} />
          </div>
        ))}
      </Modal>
    </>
  );
};

export default Service;
